<template>
  <div class="successContainer">
    <index-title-text :text="successText" :link="link" />
    <ul class="successShowImgCon">
      <li class="successImg" v-for="item in text" :key="item">
        <img class="sI" :src="baseUrl + item.img" alt="" />
        <p class="sIt">{{ item.EN_title }}</p>
        <p class="sP">{{ item.EN_description }}</p>
        <div
          class="sIfilter"
          :style="{
            background:
              (item.ID <= 3 && item.ID % 2 == 0) || (item.ID > 3 && item.ID % 2 != 0)
                ? 'rgba(4, 29, 114, 0.35)'
                : 'rgba(31, 137, 235, 0.35)',
          }"
        ></div>
      </li>
    </ul>
  </div>
</template>
<script>
import IndexTitleText from "../../components/IndexTitleText.vue";

export default {
  components: { IndexTitleText },
  data() {
    return {
      successText: "Cases",
      link: "/success",
      baseUrl: "http://api.ligy.cn/",
      text: [
        /*      {
          title: "Children Instruments",
          content: "Early Stage Education",
          img: require("@/static/img/advantage1.jpg"),
          bg: "",
        },
        {
          title: "Chess",
          content: "Artificial Intelligent",
          img: require("@/static/img/advantage3.jpg"),
          bg: "background: rgba(31, 137, 235, 0.35)",
        },
        {
          title: "Education & Inspiration",
          content: "Artificial Intelligent",
          img: require("@/static/img/advantage2.jpg"),
          bg: "",
        },
        {
          title: "Massaging Armchair",
          content: "Manufacturing and Sell",
          img: require("@/static/img/advantage4.jpg"),
          bg: "background: rgba(31, 137, 235, 0.35)",
        },
        {
          title: "Chess",
          content: "Artificial Intelligent",
          img: require("@/static/img/advantage3.jpg"),
          bg: "background: rgba(31, 137, 235, 0.35)",
        },
        {
          title: "Massaging Armchair",
          content: "Manufacturing and Sell",
          img: require("@/static/img/advantage1.jpg"),
          bg: "",
        },
        {
          title: "Children Instruments",
          content: "Early Stage Education",
          img: require("@/static/img/advantage4.jpg"),
          bg: "background: rgba(31, 137, 235, 0.35)",
        },
        {
          title: "Education & Inspiration",
          content: "Artificial Intelligent",
          img: require("@/static/img/advantage3.jpg"),
          bg: "",
        }, */
      ],
    };
  },
  created() {
    this.$axios({
      method: "GET",
      url: "/website/getCases",
    }).then((res) => {
      /*       for (let key in this.text) res.data.data.content[key] = this.text[key]; */
      this.text = res.data.data.content;
    });
    console.log(this.text);
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/*成功案例*/
.successContainer {
  margin-bottom: 100px;
  width: 100%;
}

.successShowImgCon {
  position: relative;
  top: 25px;
  margin-left: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 50% 50%;
  width: 90%;
  height: 424px;
}

.successShowImgCon > a {
  text-decoration: none;
  list-style: none;
}

.successImg {
  position: relative;
  opacity: 1;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  z-index: 0;
  list-style: none;
  overflow: hidden;
}

.successImg > .sI {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  transition: all 0.5s;
}

.successImg:hover .sI {
  opacity: 1;
  transform: scale(1.2);
  transition: all 0.5s;
}

.successImg > .sIt {
  display: block;
  position: relative;
  margin: 0 auto;
  font-family: PingFang SC;
  font-style: normal;
  font-size: 20px;
  /* line-height: 150px; */
  align-items: center;
  text-align: center;
  color: #ffffff;
  transition: all 0.5s;
  z-index: 3;
  top: 36%;
}

.successImg > .sP {
  position: relative;
  margin: 0 auto;
  font-family: PingFang SC;
  font-style: normal;
  font-size: 14px;
  line-height: px;
  /* identical to box height */
  align-items: center;
  text-align: center;
  color: #ffffff;
  transition: all 0.5s;
  z-index: 3;
  top: 35%;
}

.sIfilter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(4, 29, 114, 0.35);
  transition: all 0.5s;
}

/* .successImg:hover .sIfilter {
  transform: scale(1.2);
  z-index: 200;
  transition: all 0.5s;
} */
/*手机端适配*/
@media screen and (max-width: 800px) {
  /*成功案例*/
  .successContainer {
    margin-top: 50px;
    margin-bottom: 100px;
    width: 100%;
  }

  .successShowImgCon {
    position: relative;
    top: 25px;
    margin-left: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 150px 150px;
    width: 90%;
    height: 300px;
  }

  .successShowImgCon > a {
    text-decoration: none;
    list-style: none;
  }

  .successImg {
    position: relative;
    opacity: 1;
    background-size: cover;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
  }

  .successImg .sI {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    transition: all 0.5s;
  }

  .successImg:hover .sI {
    opacity: 1;
    transform: scale(1.2);
    z-index: -1;
    transition: all 0.5s;
  }

  .successImg > .sIt {
    position: relative;
    margin: 0 auto;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }

  .successImg > .sP {
    position: relative;
    margin: 0 auto;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 14px;
    line-height: 9px;
    /* identical to box height */
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
}
@media screen and (max-width: 650px) {
  .successImg > .sIt {
    position: relative;
    margin: 0 auto;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }

  .successImg > .sP {
    position: relative;
    margin: 0 auto;
    font-family: PingFang SC;
    font-style: normal;
    font-size: 10px;
    line-height: 19px;
    /* identical to box height */
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
}
</style>
