<template>
    <div style="width: 100%">
        <div class="container">
            <page-header-image />
            <div style="margin-top: 200px"></div>
            <success-story />
            <div style="margin-top: 150px"></div>
        </div>
        <!-- <float-box /> -->
        <!-- <index-footer /> -->
    </div>
</template>
<script>
import FloatBox from '../components/floatBox.vue';
import IndexFooter from '../components/IndexFooter.vue';
import PageHeaderImage from '../components/PageHeaderImage.vue';
import SuccessStory from './ENHome/successStory.vue';

export default {
  components: {
    PageHeaderImage,
    FloatBox,
    IndexFooter,
    SuccessStory,
  },
};
</script>
<style scoped>
    *{
        padding: 0;
        box-sizing: border-box;
        margin: 0;
    }
    .container{
        width: inherit;
    }
</style>
